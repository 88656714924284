'use strict';

angular.module('enervexSalesappApp').service('JobService', function($stateParams, Account, AccountLocation, Company, Contact, Job, JobStatus, User, State){
	this.getAccount = getAccount;
	this.getAccountLocations = getAccountLocations;
	this.getJob = getJob;
	this.getJobs = getJobs;
	this.createJob = createJob;
	this.updateJob = updateJob;
	this.updateJobStatus = updateJobStatus;
	this.deleteJob = deleteJob;
	this.getStates = getStates;
	this.getContacts = getContacts;
	this.getCompanies = getCompanies;
	this.getStatus = getStatus;
	this.getUsers = getUsers;

	function getAccount(){
		return Account.get({
			id: $stateParams.accountId
		})
	}
	function getAccountLocations(){
		return AccountLocation.query({ accountId: $stateParams.accountId });
	}
	function getJob(){
		return Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		});
	}
	function getJobs(){
		return Job.query({ accountId: $stateParams.accountId, limit: 1000 });
	}
	function createJob(job){
		return Job.save({ accountId: $stateParams.accountId }, job);
	}
	function updateJob(job){
		return Job.update({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}, job);
	}
	function updateJobStatus(status){
		return Job.update({
			accountId: $stateParams.accountId,
			id: $stateParams.jobId
		}, {
			status: status
		});
	}
	function deleteJob(job){
		return Job.delete({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}, job);
	}
	function getStates(){
		return State.query({limit: 300});
	}
	function getContacts(){
		return Contact.query({ accountId: $stateParams.accountId });
	}
	function getCompanies(){
		return Company.query({ accountId: $stateParams.accountId });
	}
	function getStatus(){
		return JobStatus.query({
			active: true
		});
	}
	function getUsers(){
		return User.query({})
	}


});
